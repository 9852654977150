import React, { Component } from 'react';
import PropTypes from 'prop-types';

// ///////////////////////////////////////////////////////////////////////
// BrowserRouter would be preferred over HashRouter, but BrowserRouter
// would require configuring the server. So we will use HashRouter here.
// Please change to BrowserRouter if you have your own backend server.
// /////////////////////////////////////////////////////////////////////////
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import 'isomorphic-fetch';
import { connect } from 'react-redux';
import NordAppBar from '../../components/header/NordAppBar';
import Footer from '../../components/footer/Footer';
import FAQ from '../doc/FAQ';
import TOU from '../doc/TOU';
import CONTACT from '../doc/CONTACT';
import Usage from '../doc/Usage';
import { compose } from 'redux';
import PrivateRoute from '../misc/PrivateRoute';
import AuthExpired from '../misc/AuthExpired';
import Home from '../home/Home';
import Nav from '../../components/nav/Nav';
import _ from 'lodash';
import ReportsPage from '../suppliers/ReportsPage';
import NotFound from '../misc/NotFound';
import withStyles from 'material-ui/styles/withStyles';
import { loadIdToken, getProfile, isTokenExpired } from '../../utils/apiUtils';
import { getClaims } from '../../utils/authUtils';
import { getLoginUrl, resolvePendingAccountActions } from '../../actions/auth';
import { getContent } from '../../actions/content';
import withRoot from '../../components/generic/withRoot';
import { createBrowserHistory } from 'history';
import { logout, exchangeCodeAndState } from '../../actions/auth';
import './app.css';
import EditContentPage from '../admin/EditContentPage';
import DEPARTMENT from '../doc/DEPARTMENT';
import CONVERSION from '../doc/CONVERSION';
import currentConfig from '../../configuration';
import ADDITIONAL_RESOURCES from '../doc/ADDITIONAL_RESOURCES';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    background: 'white',
  },
  contentMain: theme.contentMain,
  contentShell: {
    backgroundColor: '#fafafa',
    height: '100%',
    marginLeft: 240,
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    flex: '1 1 auto',
  },
  header: {
    maxHeight: 64,
  },
  body: {
    paddingRight: 0,
  },
  appWhole: {
    height: '100vh',
    width: '100%',
    zIndex: 1,
  },
});

const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });
function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}
class App extends Component {
  constructor(props) {
    super(props);

    this.handleLogout = this.handleLogout.bind(this);
  }
  handleLogout() {
    const { accessToken } = this.props.user;
    this.props.dispatch(logout(accessToken));
  }
  doProfile() {
    const user = getProfile();
    const claims = getClaims(user);
    alert(`
        user: ${claims.sub},
        email: ${claims.email},
        roles: ${claims.roles},
        groups: ${claims.groups},
        permissions: ${claims.permissions}

    `);
  }

  componentWillReceiveProps(nextProps) {
    const user = loadIdToken();

    if (user && isTokenExpired()) {
      history.push('/#/expired');
    }
  }

  async componentWillMount() {
    this.props.dispatch(getContent());

    const user = loadIdToken();

    const profile = getProfile();

    if (profile && profile.hasPendingProfileRequirements()) {
      this.props.dispatch(resolvePendingAccountActions());
    }

    const code = getQueryVariable('code');

    const err = getQueryVariable('error');

    if ((!user && code) || err) {
      const authstate = getQueryVariable('state');
      this.props.dispatch(exchangeCodeAndState(code, authstate, history, err));
      // this.props.dispatch(goHome(history));
    }

    this.props.dispatch(getLoginUrl());
  }
  render() {
    const { classes } = this.props;

    const user = getProfile();

    const isAuthenticated = true && user;
    const isExpired = this.props.authExpired;

    const { accountUrl } = currentConfig;
    const profileUrl = `${accountUrl}/profile`;

    let userName = null;
    if (isAuthenticated) {
      userName = user.user;
      // accountUrl += '/callback';
    }

    const nag = this.props.user
      ? this.props.user.hasPendingProfileRequirements()
      : false;

    const isContentEditor = user && user.isContentEditor();

    return (
      <Router history={history}>
        <div>
          <div className={classes.root}>
            <NordAppBar
              user={user || null}
              logout={this.handleLogout}
              menuAnchorElement={this.props.menuAnchorElement}
              loginUrl={this.props.loginUrl}
              showProfileMenu={this.props.showProfileMenu}
              dispatch={this.props.dispatch}
              accountUrl={accountUrl}
              hasPendingProfileRequirements={nag}
            />
            <div className={classes.header}>
              <Nav
                user={_.isObject(user) ? user : null}
                profileUrl={profileUrl}
                accountUrl={accountUrl}
                gettingLoginUrl={this.props.gettingLoginUrl}
                loading={this.props.loading}
                dispatch={this.props.dispatch}
                loggingIn={this.props.loggingIn}
                loggingOut={this.props.loggingOut}
                loginUrl={this.props.loginUrl}
                doProfile={() => this.doProfile()}
                handleLogout={this.handleLogout}
              />
            </div>
            <div className={classes.contentShell}>
              <div className={classes.contentMain}>
                <Switch>
                  <Route exact path='/' component={Home} />
                  <Route exact path='/faq' component={FAQ} />
                  <Route exact path='/terms' component={TOU} />
                  <Route exact path='/contact' component={CONTACT} />
                  <Route exact path='/usage' component={Usage} />
                  <Route exact path='/department' component={DEPARTMENT} />
                  <Route exact path='/system-changes' component={CONVERSION} />
                  <Route
                    exact
                    path='/additional-resources'
                    component={ADDITIONAL_RESOURCES}
                  />
                  <Route
                    exact
                    path='/expired'
                    loginUrl={this.props.loginUrl}
                    component={AuthExpired}
                  />
                  <PrivateRoute
                    path='/reports/:supplierId'
                    isAuthenticated={isAuthenticated}
                    isExpired={isExpired}
                    history={history}
                    logoutUrl={this.props.logoutUrl}
                    loginUrl={this.props.loginUrl}
                    component={ReportsPage}
                  />

                  <PrivateRoute
                    path='/admin/content'
                    isAuthenticated={isContentEditor}
                    isExpired={isExpired}
                    user={user}
                    exact
                    history={history}
                    logoutUrl={this.props.logoutUrl}
                    loginUrl={this.props.loginUrl}
                    component={EditContentPage}
                  />
                  <Route component={NotFound} />
                </Switch>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Router>
    );
  }
}

App.propTypes = {
  user: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

App.contextTypes = {
  store: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { auth, ui } = state;

  return {
    user: auth && auth.user ? auth.user : null,
    loginUrl: auth.loginUrl,
    loggingOut: auth.loggingOut,
    loggingIn: auth.loggingIn,
    authExpired: auth.expired,
    loading: auth.loggingIn,
    gettingLoginUrl: auth.gettingLoginUrl,
    menuAnchorElement: ui.menuAnchorElement,
    showProfileMenu: ui.showProfileMenu,
  };
};

if (navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}

export default withRoot(
  compose(withStyles(styles), connect(mapStateToProps))(App)
);
