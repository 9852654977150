import { ConfigObject } from './types';

// Deployment URL: https://partnerconnect-staging.nordstrom.com
export const external: ConfigObject = {
  accountUrl: 'https://partneraccess-nonprod.nordstrom.com',
  appUrl: 'https://partnerconnect-staging.nordstrom.com',
  authRedirectUrl: 'https://partnerconnect-staging.nordstrom.com',
  authServiceUrl:
    'https://partnerconnect-staging.nordstrom.com/app01396/vendorauthentication',
  reportApiUrl:
    'https://partnerconnect-staging.nordstrom.com/app01396/vendorreporting'
};

// Deployment URL: https://vendor-portal-si.nordstromaws.app
export const internal: ConfigObject = {
  accountUrl: 'https://partneraccess-nonprod.nordstrom.com',
  appUrl: 'https://vendor-portal-si.nordstromaws.app',
  authRedirectUrl: 'https://vendor-portal-si.nordstromaws.app',
  authServiceUrl:
    'https://api-nonprod.nordstromaws.app/app01396/vendorauthentication',
  reportApiUrl: 'https://api-nonprod.nordstromaws.app/app01396/vendorreporting'
};
