import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './home.css';
import { connect } from 'react-redux';

import { getSuppliers, checkReporting } from '../../actions/suppliers';
import Alert from '../../components/generic/Alert';
import Typography from 'material-ui/Typography/Typography';
import ListItem from 'material-ui/List/ListItem';
import List from 'material-ui/List/List';
import { compose } from 'redux';
import withStyles from 'material-ui/styles/withStyles';
import Chip from 'material-ui/Chip/Chip';
import Icon from 'material-ui/Icon/Icon';

import Paper from 'material-ui/Paper/Paper';
import { FeatureFlag } from '../../components/generic/FeatureFlag';

const styles = (theme) => ({
  listItem: {
    '& a, a:visited,a:hover': {
      color: theme.typography.color,
    },
    '& a': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flex: 1,
    },
    'paddingBottom': '0',
    'borderBottom': '0!important',
    'paddingLeft': 0,
  },
  unavailable: {
    'display': 'flex',
    'justifyItems': 'center',
    'alignItems': 'center',
    '& div svg': {
      fontSize: '48px',
    },
  },
  supplierList: {
    justifyContent: 'flex-start',
    padding: theme.spacing.unit,
    paddingLeft: 0,
  },
  chip: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: 0,
    marginBottom: 0,
    height: 24,
    backgroundColor: theme.palette.primary[400],
    fontWeight: 'bold',
    color: 'white',
  },
  itemPaper: {
    flex: 1,
    padding: theme.spacing.unit,
    marginBottom: '0',
  },
});

class SupplierLanding extends Component {
  componentWillMount() {
    if (this.props.user) {
      this.props.getSuppliers(this.props.user.getSupplierIds());
      this.props.checkReporting();
    }
  }
  isAdmin(supplier) {
    const { user } = this.props;

    if (user.hasRole(supplier.id, 'pcp_admin')) {
      return <span className='adminLabel'>admin</span>;
    }

    return null;
  }
  renderUnavailable() {
    const { classes } = this.props;
    return (
      <div className={classes.unavailable}>
        <Alert
          variant='warning'
          message={
            'Supplier Reports are unavailable at this time since we are still processing last weeks data. Please check back later to generate reports.'
          }
        />
      </div>
    );
  }
  renderMain() {
    const { suppliers, user, loadingSuppliers, classes } = this.props;
    let chip = null;
    const adminIcon = <Icon icon='person'></Icon>;

    const supplierPopulatedContent = (
      <div className='landing'>
        <Typography variant='subheading'>
          Select the supplier you wish to report on from the list below:
        </Typography>
        <List className={classes.supplierList}>
          {suppliers.map((s, i) => {
            if (this.isAdmin(s)) {
              chip = (
                <Chip
                  variant='outlined'
                  label='admin'
                  className={classes.chip}
                />
              );
            }

            return (
              <ListItem key={i} className={classes.listItem}>
                <Link to={'/reports/' + s.id}>
                  <Paper className={classes.itemPaper}>
                    <span className='fa fa-building-o'></span> {chip} {s.name} (
                    <span className='vendorNumber'>{s.id}</span>)
                  </Paper>
                </Link>
              </ListItem>
            );
          })}
        </List>
      </div>
    );

    const supplierEmptyContent = (
      <Alert
        variant='warning'
        message='We are unable to retrieve any supplier records associated to the account you are logged in as.'
      />
    );

    if (loadingSuppliers === true && !suppliers.length) {
      return <Alert variant='info' message='Loading Supplier Info ...' />;
    } else {
      return suppliers && suppliers.length
        ? supplierPopulatedContent
        : loadingSuppliers === false
        ? supplierEmptyContent
        : null;
    }
  }

  render() {
    const { reportsAvailable } = this.props;
    return (
      <div>
        {reportsAvailable ? this.renderMain() : this.renderUnavailable()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;

  const { suppliers, loadingSuppliers, reportsAvailable } = state.suppliers;
  return { suppliers, loadingSuppliers, user, reportsAvailable };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getSuppliers, checkReporting })
)(SupplierLanding);
