import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { clearSearch } from '../../actions/search';
import { cancelEdit } from '../../actions/content';
import Divider from 'material-ui/Divider';
import withStyles from 'material-ui/styles/withStyles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ListItem, ListItemIcon, ListItemText } from 'material-ui/List';
import List from 'material-ui/List/List';
import Typography from 'material-ui/Typography/Typography';
import Business from 'material-ui-icons/Business';
import NordstromLogo from '../../assets/nordstrom-logo.svg';

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    background: 'white',
    display: 'block',
    textDecoration: 'none',
    width: drawerWidth,
    position: 'absolute',
    height: '-webkit-fill-available',
  },
  copyright: {
    display: 'inline-block',
    width: '50%',
    textAlign: 'left',
  },
  drawer: {
    width: drawerWidth,
    margin: 0,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    flexGrow: 1,
    flexFlow: 'column',
    alignItems: 'stretch',
    overflow: 'auto',
  },
  drawerHeader: {
    textAlign: 'center',
    height: 23,
    padding: 20,
  },
  listContainer: {
    width: 240,
    zIndex: 1,
    marginBottom: '3em',
    backgroundColor: 'white',
  },
  nordstromLogo: {
    width: '95%',
  },
  profileDivider: {
    margin: '7px 0',
  },
  navLink: {},
  listItemIcon: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  subheading: {
    padding: theme.spacing.unit,
    background: theme.palette.offWhite,
    color: theme.palette.primary[700],
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    lineHeight: '24px',
  },
});

class NavListItem extends Component {
  renderLink = (itemProps) => (
    <Link
      className={this.props.classes.navLink}
      to={this.props.to}
      {...itemProps}
    />
  );

  render() {
    const { icon, primary, secondary, to } = this.props;
    const displayIcon = <i className={`fa fa-fw ${icon}`}></i>;
    return (
      <ListItem
        className={this.props.classes + ' ' + this.props.classes.listItemIcon}
        selected={false}
        button
        component={this.renderLink}
      >
        <ListItemIcon
          style={{ lineHeight: '24px', fontSize: '16px', fontWeight: 400 }}
        >
          {displayIcon}
        </ListItemIcon>
        <ListItemText
          style={{ lineHeight: '24px', fontSize: '16px', fontWeight: 400 }}
          inset
          primary={primary}
          secondary={secondary}
        />
      </ListItem>
    );
  }
}

class Nav extends Component {
  constructor() {
    super();
    this.onLogoutClick = this.onLogoutClick.bind(this);
  }

  onLogoutClick = (event) => {
    event.preventDefault();

    this.props.handleLogout(this.props.user.accessToken);
  };

  logoutLink() {
    return this.props.loggingOut ? (
      <li>
        <i className='fa fa-fw fa-hourglass-half'></i> Logging out...
      </li>
    ) : (
      <li>
        <a
          className='logout'
          style={{ cursor: 'pointer' }}
          onClick={this.onLogoutClick}
        >
          <i className='fa fa-fw fa-power-off' /> Log Out
        </a>
      </li>
    );
  }

  accountLink(accountUrl, profileUrl) {
    const accountLinkUrl = this.props.user.getAccountUrl(
      this.props.accountUrl,
      this.props.profileUrl
    );

    return this.props.user && accountLinkUrl ? (
      <li>
        {' '}
        <a href={accountLinkUrl} target='_new'>
          <i className='fa fa-fw fa-user'></i> Account
        </a>
      </li>
    ) : null;
  }

  loginLink() {
    if (this.props.loggingIn) return null;
    if (this.props.user) return null;
    if (this.props.gettingLoginUrl === false && !this.props.loginUrl) {
      return (
        <li>
          <i className='fa fa-fw fa-exclamation-triangle'></i> Unable to log in
        </li>
      );
    }
    if (this.props.gettingLoginUrl) {
      return (
        <li>
          <a className='login' href={this.props.loginUrl}>
            <i className='fa fa-fw fa-laptop'></i> Log In
          </a>
        </li>
      );
    }
    if (this.props.loginUrl && !this.props.gettingLoginUrl) {
      return (
        <li>
          <a className='login' href={this.props.loginUrl}>
            <i className='fa fa-fw fa-laptop'></i> Log In
          </a>
        </li>
      );
    }
  }
  reportsClick() {
    this.props.dispatch(clearSearch());
    this.props.dispatch(cancelEdit());
  }

  renderAdminLinks() {
    const { user, classes } = this.props;
    if (!user) {
      return null;
    }
    if (user.isContentEditor() || user.isSuperAdmin()) {
      return (
        <div>
          <Divider />
          <Typography variant='subheading' className={classes.subheading}>
            <Business />
            &nbsp;Administration
          </Typography>
          <Divider />
          {user.isContentEditor() ? (
            <NavListItem
              {...this.props}
              testId='admincontenteditor'
              primary='Edit Home Page'
              to='/admin/content'
              icon='fa-pencil-square-o'
            />
          ) : null}
        </div>
      );
    }
  }

  render() {
    const { user, loading, accountUrl, profileUrl, classes } = this.props;

    const pathname = this.props.history.location.pathname;
    const isLoginPage = pathname.indexOf('login') > -1;
    const isSuppliersPage = pathname.indexOf('suppliers') > -1;
    const isUsersPage = pathname.indexOf('users') > -1;
    const isReposPage = pathname.indexOf('repos') > -1;
    const reportsIcon = <i className='fa fa-fw fa-cubes'></i>;
    return (
      <nav className={classes.root}>
        <div className={classes.navContainer}>
          <div className={classes.drawer}>
            <div className={classes.drawerHeader}>
              <Link
                to='/'
                onClick={() => {
                  this.props.dispatch(clearSearch());
                }}
              >
                <img
                  data-test-id='nordstrom-logo'
                  src={NordstromLogo}
                  className={classes.nordstromLogo}
                  alt='nordstrom logo'
                />
              </Link>
            </div>
            <Divider />

            <List className={classes.listContainer}>
              {user ? (
                <NavListItem
                  {...this.props}
                  onClick={() => {
                    this.reportsClick();
                  }}
                  testId='nlreports'
                  to={'/'}
                  icon='fa-cubes'
                  primary='Reports'
                />
              ) : null}
              <NavListItem
                {...this.props}
                testId='nluserguide'
                primary='User Guide'
                to='/usage'
                icon='fa-list-alt'
              />
              <NavListItem
                {...this.props}
                testId='nlfaq'
                primary='FAQ'
                to='/faq'
                icon='fa-question'
              />
              <NavListItem
                {...this.props}
                testId='nlfaq'
                primary='System Changes'
                to='/system-changes'
                icon='fa-bullhorn'
              />
              <NavListItem
                {...this.props}
                testId='nlfaq'
                primary='Guide To Dept Changes'
                to='/department'
                icon='fa-exclamation-circle'
              />
              <NavListItem
                {...this.props}
                testId='nladditionalResources'
                primary='Additional Resources'
                to='/additional-resources'
                icon='fa-book'
              />
              <NavListItem
                {...this.props}
                testId='nlcontact'
                primary='Contact'
                to='/contact'
                icon='fa-address-card'
              />
              {this.renderAdminLinks()}
            </List>
          </div>
        </div>
      </nav>
    );
  }
}

Nav.propTypes = {
  user: PropTypes.object,
  loginUrl: PropTypes.string,
  logoutUrl: PropTypes.string,
  accountUrl: PropTypes.string,
  loggingOut: PropTypes.bool,
  loggingIn: PropTypes.bool,
  loading: PropTypes.bool,
  handleLogout: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  gettingLoginUrl: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default withRouter(
  compose(withStyles(styles), connect(mapStateToProps))(Nav)
);
