/* eslint-disable flowtype/require-valid-file-annotation */

import React, { Component } from 'react';
import AppBar from 'material-ui/AppBar';
import ArrowDropDown from 'material-ui-icons/ArrowDropDown';
import Button from 'material-ui/Button';
import Menu, { MenuItem } from 'material-ui/Menu';
import PersonIcon from 'material-ui-icons/Person';
import WarningIcon from 'material-ui-icons/Warning';
import PropTypes from 'prop-types';
import Toolbar from 'material-ui/Toolbar';
import Typography from 'material-ui/Typography';
import Snackbar from 'material-ui/Snackbar';

import withStyles from 'material-ui/styles/withStyles';
import { bindActionCreators, compose } from 'redux';
import { setMenuAnchorElement, setShowProfileMenu } from '../../reducers/ui';
import { connect } from 'react-redux';

const PROP_TYPES = {
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
  loginUrl: PropTypes.string.isRequired,
  menuAnchorElement: PropTypes.object.isRequired,
  showProfileMenu: PropTypes.bool.isRequired,
  accountUrl: PropTypes.string.isRequired,
  hasPendingProfileRequirements: PropTypes.bool.isRequired,
};

const drawerWidth = 240;

const styles = (theme) => ({
  appBar: {
    position: 'absolute',
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    order: 1,
    backgroundColor: '#607d8b',
    height: 64,
  },
  flex: {
    flex: 1,
  },
  assignmentButton: {
    color: 'white',
    minWidth: 0,
    width: 0,
  },
  menuItem: {
    minWidth: '7em',
    color: 'black',
  },
  loginButton: {
    '&:active': {
      color: 'white',
    },
    '&:hover': {
      color: 'white',
    },
    '&:visited': {
      color: 'white',
    },
    'minWidth': '7em',
    'color': 'white',
  },
  partnersToolTip: {
    width: 200,
  },
  partnersToolTipTitle: {
    display: 'block',
    fontSize: 14,
    marginBottom: 5,
    borderBottom: '1px solid #fff',
  },
  partnersToolTipPartner: {
    display: 'block',
    marginRight: 5,
  },
  profileButton: {
    color: 'white',
  },
  profileMenu: {
    minWidth: '2em',

    width: '1em',
  },
  title: {
    fontSize: '1.3125rem',
    fontWeight: '500',
    flex: 1,
  },
  snackbar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.error[500],
    color: theme.palette.white[500],
    paddingTop: 0,
    paddingBottom: '0.72em',
    minWidth: '100%',
    maxWidth: 'none!important',
    width: '100%',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.only('md')]: {
      paddingBottom: '1.75em',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingBottom: '2.0em',
    },
  },
  snackbarMessage: {
    'minWidth': '100%',
    '& p': {
      display: 'flex',
    },
  },
});

export class NordAppBar extends Component {
  handleClick(event) {
    this.props.setMenuAnchorElement(event.currentTarget);
    this.props.setShowProfileMenu(true);
  }

  renderProfileMenuItem() {
    const { changePage, classes, showProfileMenu } = this.props;
    return (
      <MenuItem
        data-test-id='profileNavButton'
        className={classes.menuItem}
        onClick={() => {
          this.props.setShowProfileMenu(false);
          window.open(this.props.accountUrl, '_new');
        }}
      >
        Account
      </MenuItem>
    );
  }
  menu(
    classes,
    firstName,
    menuAnchorElement,
    showProfileMenu,
    displayName,
    user
  ) {
    return (
      <pcmenu>
        <Button
          data-test-id='profileButton'
          aria-owns={this.showProfileMenu ? 'profile-menu' : null}
          aria-haspopup='true'
          className={classes.profileButton}
          onClick={(event) => this.handleClick(event)}
        >
          <PersonIcon />{' '}
          <span data-test-id='login-name'>{`${displayName}`}</span>{' '}
          <ArrowDropDown />
        </Button>
        <Menu
          open={showProfileMenu}
          id='profile-menu'
          anchorEl={menuAnchorElement}
          className={classes.profileMenu}
          onClose={() => {
            this.props.setShowProfileMenu(false);
          }}
        >
          {this.renderProfileMenuItem()}

          <MenuItem
            data-test-id='logoutNavButton'
            className={classes.menuItem}
            onClick={() => {
              this.props.logout();
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </pcmenu>
    );
  }

  loginMenu(classes) {
    const { loginUrl, loggingIn } = this.props;
    if (loggingIn) {
      return (
        <span>
          <i className='fa fa-clock-o' aria-hidden='true'></i>
        </span>
      );
    }
    const loginButton = (
      <Button
        data-test-id='profileButton'
        aria-owns={this.showProfileMenu ? 'profile-menu' : null}
        aria-haspopup='true'
        className={classes.loginButton}
        href={loginUrl}
      >
        <i className='fa fa-fw fa-laptop'></i> Log In
      </Button>
    );

    return loginUrl ? loginButton : null;
  }
  profReqs(classes, accountUrl) {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={true}
        onClose={this.handleClose}
        ContentProps={{
          'classes': {
            root: classes.snackbar,
          },
          'aria-describedby': 'message-id',
        }}
        message={
          <span
            onClick={() => {
              window.location.href = accountUrl;
            }}
            id='message-id'
          >
            <WarningIcon />
            <Typography
              className={classes.snackbarMessage}
              style={{ color: 'white' }}
            >
              To ensure continued access to Supplier Reports,{' '}
              <a href={accountUrl}>click here</a> to set your security question
              immediately.
            </Typography>
          </span>
        }
      />
    );
  }
  render() {
    const {
      classes,
      firstName,
      menuAnchorElement,
      showProfileMenu,
      logout,
      hasPendingProfileRequirements,
      accountUrl,
    } = this.props;
    const { user } = this.props;
    const { displayName } = user || {};

    return (
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography
            data-test-id='heading'
            type='title'
            color='inherit'
            variant='headline'
            className={classes.title}
          >
            Supplier Reports
          </Typography>
          {hasPendingProfileRequirements
            ? this.profReqs(classes, accountUrl)
            : null}
          {user
            ? this.menu(
                classes,
                firstName,
                menuAnchorElement,
                showProfileMenu,
                displayName,
                user
              )
            : this.loginMenu(classes)}
        </Toolbar>
      </AppBar>
    );
  }
}

NordAppBar.propTypes = PROP_TYPES;

const mapStateToProps = (state) => {
  const { loggingIn } = state.auth;

  return { loggingIn };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setMenuAnchorElement,
      setShowProfileMenu,
    },
    dispatch
  );

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(NordAppBar);
