import { ConfigObject } from './types';

// Deployment URL: https://partner.nordstrom.com
export const external: ConfigObject = {
  accountUrl: 'https://partneraccess.nordstrom.com',
  appUrl: 'https://partner.nordstrom.com',
  authRedirectUrl: 'https://partner.nordstrom.com',
  authServiceUrl: 'https://partner.nordstrom.com/app01396/vendorauthentication',
  reportApiUrl: 'https://partner.nordstrom.com/app01396/vendorreporting'
};

// Deployment URL: https://vendor-portal.nordstromaws.app
export const internal: ConfigObject = {
  accountUrl: 'https://partneraccess.nordstrom.com',
  appUrl: 'https://vendor-portal.nordstromaws.app',
  authRedirectUrl: 'https://vendor-portal.nordstromaws.app',
  authServiceUrl: 'https://api.nordstromaws.app/app01396/vendorauthentication',
  reportApiUrl: 'https://api.nordstromaws.app/app01396/vendorreporting'
};
